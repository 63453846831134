import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./index.css";
import firebase from "firebase/app";

let firebaseConfig = {
  apiKey: "AIzaSyA20YEPOUz6NXGMfGmw8HZr_u-8q1r7KYo",
  authDomain: "trevira-71060.firebaseapp.com",
  projectId: "trevira-71060",
  storageBucket: "trevira-71060.appspot.com",
  messagingSenderId: "878790678107",
  appId: "1:878790678107:web:bed1121601f95fcc97917e",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
createApp(App).use(store).use(router).mount("#app");
