<template>
  <div>
    <Nav />
    <router-view class="w-11/12 md:w-9/12 mx-auto" />
    <Livechat :snippet="'5f8d8449f91e4b431ec5aacf/default'" />
    <Footer />
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";
import Livechat from "@/components/Livechat.vue";

export default {
  name: "Home",
  components: {
    Nav,
    Footer,
    Livechat,
  },
};
</script>
