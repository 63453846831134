<template>
  <div></div>
</template>

<script>
import * as external from "./scriptmanager.js";

export default {
  name: "Livechat",
  props: {
    index: {
      type: Number,
    },
    snippet: {
      type: String,
    },
  },
  watch: {
    snippet(newValue, oldValue) {
      if (newValue !== oldValue) {
        console.log(newValue);
        console.log(oldValue);
        if (newValue !== oldValue) {
          const chat = document.getElementsByClassName("livechat");
          chat[0].src = "https://embed.tawk.to/" + newValue;
          location.reload();
        }
      }
    },
  },
  mounted() {
    this.initializeLivechat();
  },
  methods: {
    initializeLivechat() {
      external.body_script("https://embed.tawk.to/" + this.snippet, "livechat");
    },
  },
  data: () => ({
    token: "",
  }),
};
</script>

<style></style>
