import { createRouter, createWebHistory } from "vue-router";
import firebase from "firebase";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/event",
    name: "Event",
    component: () => import("../views/Event.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/meeting/:roomName",
    name: "Meeting",
    component: () => import("../views/Meeting.vue"),
    meta: {
      auth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  firebase.auth().onAuthStateChanged((user) => {
    console.log(to);
    if (user) {
      if (to.query.next != null) {
        next(`${to.query.next}`);
      } else {
        next();
      }
    } else {
      if (to.matched.some((record) => record.meta.auth)) {
        next({
          path: "/login",
          query: {
            next: to.fullPath,
          },
        });
      } else {
        next();
      }
    }
  });
});

export default router;
