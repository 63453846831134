<template>
  <div class="w-11/12 md:w-10/12 mx-auto grid grid-flow-row mt-10">
    <img src="../assets/logo.jpg" class="w-32 md:w-48" alt="" />
    <div class="justify-self-end">
      <button
        class="bg-gray-700 py-2 px-4 text-white rounded-md"
        v-if="this.$route.fullPath == '/event'"
        @click="logout()"
      >
        Logout
      </button>
    </div>
    <div class="justify-self-end">
      <router-link
        class="bg-gray-700 py-2 px-4 text-white rounded-md"
        v-if="this.$route.name == 'Meeting'"
        to="/event"
      >
        back to the stream
      </router-link>
    </div>

    <img
      v-if="this.$route.fullPath == '/login'"
      src="../assets/background.jpg"
      class="mt-10 border-b-8 border-brand-brand"
      alt=""
    />
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  name: "Nav",
  methods: {
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.push("/login");
        });
    },
  },
};
</script>

<style scoped></style>
