<template>
  <div class="bg-brand-grey py-10">
    <div class="w-10/12 md:w-7/12 text-center mx-auto">
      <div class="grid grid-cols-2 gap-4 mb-5">
        <a
          href="https://www.trevira.de/en/imprint"
          target="_blank"
          class="justify-self-end hover:underline"
        >
          Imprint
        </a>
        <a
          href="https://www.trevira.de/en/data-protection-statement"
          target="_blank"
          class="justify-self-start hover:underline"
        >
          Privacy Policy
        </a>
      </div>
      <p class="mt-5">&copy; 2021 Trevira GmbH</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped></style>
